// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
//import MainBody from './views/MainBody.vue'; // Create this component
import FrontPage from '@/views/FrontPage'
import ProductBuild from '@/views/ProductBuild'
import StorageBuilder from '@/views/storage_new/StorageBuilder'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: FrontPage
  },
  {
    path: '/byggdittlager',
    name: 'ProductBuild',
    component: ProductBuild
  },
  {
    path: '/byggnyttlager',
    name: 'StorageBuilder',
    component: StorageBuilder
  }
  // You can add more routes here for other pages
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
