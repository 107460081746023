<template>
  <div class="container mx-auto flex flex-col">
    <div id="selector" class="top-0 z-20 grid grid-cols-3 grid-rows-[8rem,2rem] rounded mb-0">
      <div class="col-start-2">
        <HorizontalStepper :steps="steps" :currentStep="currentStep" @changed-step="updateCurrentStep" />
      </div>
    </div>
    <!-- <div class="grid row-span-2 ">
        <div class="flex md:transform justify-center">
          <div
            class="border-transparent rounded-lg text-center mx-auto md:mx-0 bg-gradient text-black shadow-lg">
            <div class="py-4">
              <div class="flex flex-row justify-center text-sm tracking-tight font-medium text-gray-700">
                <p class="mx-3">Leie</p>
                <label for="toggle" class="flex items-center cursor-pointer">
                  <div class="relative">                
                    <input id="toggle" type="checkbox" class="hidden" onclick="" />
                    <div class="w-10 h-3 bg-gray-400 rounded-full shadow-inner"></div>
                    <div class="toggle_dot absolute w-5 h-5 bg-white rounded-full shadow inset-y-0 left-0"></div>
                  </div>
                </label>
                <p class="mx-3">Eie</p>
              </div>
              <div id="month" class="font-bold text-6xl month hidden">&dollar;24.99</div>
              <div id="annual" class="font-bold text-6xl annual">&dollar;249.99</div>
            </div>
          </div>
        </div>
      </div> -->

    <div id="data">
      <SizeConfig :size_selected_prop="size_selected" @selected_size="set_storage_size" v-if="currentStep === 0" />
      <ElecConfig :amp_selected_prop="electrical_selected" @selected_electrical="set_electrical"
        v-else-if="currentStep === 1" />
      <ExtraConfig :extras_selected="extra_added" @extras_changed="set_extra_added" v-else-if="currentStep === 2" />
      <FinalConfig :cost_set="cost" :extras="extra_added" :amp="electrical_selected" :size="size_selected"
        v-else-if="currentStep === 3" />
    </div>
    <div class="grid grid-rows-2">
      <div class="grid grid-cols-4">
        <div @click="prev"><a
            class="mt-4 block rounded-lg bg-blue-600 px-6 py-2.5 text-center font-medium capitalize leading-5 text-white hover:bg-blue-500 lg:mt-0 lg:w-auto"
            href="#">Forrige</a></div>
        <div @click="next" class="col-start-4"><a
            class="mt-4 block rounded-lg bg-blue-600 px-6 py-2.5 text-center font-medium capitalize leading-5 text-white hover:bg-blue-500 lg:mt-0 lg:w-auto"
            href="#">Neste</a></div>
      </div>
    </div>
  </div>
</template>

<script>
import HorizontalStepper from '@/components/HorizontalStepper.vue';
import SizeConfig from '@/views/storage/Size.vue'
import ElecConfig from '@/views/storage/Electrical.vue'
import ExtraConfig from '@/views/storage/Extra.vue'
import FinalConfig from '@/views/storage/Final.vue'

/*
  Need to specify an array of content here.
  Then once an item is selected from the horizontal step,
  the data in the rectangle should shift to that component.
  Then inside the data, we specify the necessary arrays with the names and also the content.
  So [ Size, Electrical, Options, Final]
  */
export default {
  name: 'HomePage',
  components: {
    HorizontalStepper,
    SizeConfig,
    ElecConfig,
    ExtraConfig,
    FinalConfig
  },
  data() {
    return {
      steps: ['Størrelse', 'Elektrisk', 'Ekstra', 'Pris'], // Array of step titles
      extra_added: { "reoler": false, 'kabinetter': false, 'ellader': false, 'lift': false },
      size_selected: 50,
      electrical_selected: 60,
      currentStep: 0, // Index of the currently active step
      cost: { "leie": 0, "eie": 0 }
    }
  },
  methods: {
    updateCurrentStep(step) {
      this.currentStep = step;
    },
    set_storage_size(size) {
      this.size_selected = size
    },
    set_electrical(size) {
      this.electrical_selected = size
    },
    set_extra_added(item, added) {
      this.extra_added[item] = added;

    },
    next() {
      this.calculate_cost()
      if (this.currentStep + 1 < this.steps.length) {
        this.updateCurrentStep(this.currentStep + 1);
      }
    },
    prev() {
      if (this.currentStep - 1 >= 0) {
        this.updateCurrentStep(this.currentStep - 1);
      }
    },
    calculate_cost() {
      this.cost["leie"] = (this.size_selected * 144) + (this.electrical_selected * 100) + (this.extra_added["reoler"] ? 1000 : 0 + this.extra_added["kabinetter"] ? 250 : 0 + this.extra_added["ellader"] ? 200 : 0 + this.extra_added["lift"] ? 500 : 0)
      this.cost["eie"] = (this.size_selected * 14400) + (this.electrical_selected * 1000) + (this.extra_added["reoler"] ? 10000 : 0 + this.extra_added["kabinetter"] ? 25000 : 0 + this.extra_added["ellader"] ? 20000 : 0 + this.extra_added["lift"] ? 50000 : 0)
    }
  }
}
</script>
<style scoped>
.nav_button_build {
  cursor: pointer;
}

a {
  color: #42b983;
}
</style>