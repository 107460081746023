<template>
    <div class="container mx-auto flex">
        <!-- <div class="columns-8xl"> -->
        <!-- <div class="bg-white py-24 sm:py-32 flex-col "> -->
        <div class="mx-auto px-6 lg:px-8 py-24 sm:py-32 ">
            <div class="mx-auto max-w-2xl sm:text-center ">
                <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Eie eller leie
                </h2>
                <div class="grid row-span-2 ">
                    <div class="flex md:transform justify-center p-8">
                        <div class="flex flex-row justify-center text-sm tracking-tight font-medium text-gray-700">
                            <p class="mx-3">Eie</p>
                            <label for="toggle" class="flex items-center cursor-pointer">
                                <div class="relative">
                                    <input id="toggle" type="checkbox" class="hidden" @click="this.leie = !this.leie" />
                                    <div class="w-10 h-3 bg-gray-400 rounded-full shadow-inner"></div>
                                    <div
                                        class="toggle_dot absolute w-5 h-5 bg-white rounded-full shadow inset-y-0 left-0">
                                    </div>
                                </div>
                            </label>
                            <p class="mx-3">Leie</p>
                        </div>
                    </div>
                </div>
                <p class="mt-6 text-lg leading-8 text-gray-600">Distinctio et nulla eum soluta et neque labore
                    quibusdam. Saepe et quasi iusto modi velit ut non voluptas in. Explicabo id ut laborum.</p>

            </div>

            <div
                class="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
                <div class="p-8 sm:p-10 lg:flex-auto">
                    <h3 class="text-2xl font-bold tracking-tight text-gray-900">{{ size }} m<sup>2</sup> med {{ amp }}
                        AMP annlegg. </h3>
                    <p class="mt-6 text-base leading-7 text-gray-600">Lorem ipsum dolor sit amet consect etur
                        adipisicing elit. Itaque amet indis perferendis blanditiis repellendus etur quidem
                        assumenda.</p>
                    <div class="mt-10 flex items-center gap-x-4">
                        <h4 class="flex-none text-sm font-semibold leading-6 text-indigo-600">Tillegg
                        </h4>
                        <div class="h-px flex-auto bg-gray-100"></div>
                    </div>
                    <ul role="list"
                        class="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
                        <li v-bind:class="extras['reoler'] ? 'extra_enabled' : 'extra_not_enabled'"
                            class="flex gap-x-3">
                            <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor"
                                aria-hidden="true">
                                <path fill-rule="evenodd"
                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                    clip-rule="evenodd" />
                            </svg>
                            Ferdig installert reoler.
                        </li>
                        <li class="flex gap-x-3"
                            v-bind:class="extras['kabinetter'] ? 'extra_enabled' : 'extra_not_enabled'">
                            <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor"
                                aria-hidden="true">
                                <path fill-rule="evenodd"
                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                    clip-rule="evenodd" />
                            </svg>
                            4 stk kabinetter installert.
                        </li>
                        <li class="flex gap-x-3"
                            v-bind:class="extras['ellader'] ? 'extra_enabled' : 'extra_not_enabled'">
                            <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor"
                                aria-hidden="true">
                                <path fill-rule="evenodd"
                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                    clip-rule="evenodd" />
                            </svg>
                            Vegg-hengt el bil lader.
                        </li>
                        <li class="flex gap-x-3" v-bind:class="extras['lift'] ? 'extra_enabled' : 'extra_not_enabled'">
                            <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor"
                                aria-hidden="true">
                                <path fill-rule="evenodd"
                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                    clip-rule="evenodd" />
                            </svg>
                            Bil-lift for kjøretøy opp til 2,5 tonn.
                        </li>
                    </ul>
                </div>
                <div class="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                    <div
                        class="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                        <div v-if="leie" class="mx-auto max-w-xs px-8">
                            <p class="text-base font-semibold text-gray-600">Leie per måned</p>
                            <p class="mt-6 flex items-baseline justify-center gap-x-2">
                                <span class="text-5xl font-bold tracking-tight text-gray-900">{{ cost_set["leie"]
                                    }}</span>
                                <span class="text-sm font-semibold leading-6 tracking-wide text-gray-600">NOK</span>
                            </p>
                            <a href="#"
                                class="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Reserver</a>
                            <p class="mt-6 text-xs leading-5 text-gray-600">Invoices and receipts available for
                                easy
                                company reimbursement</p>
                        </div>
                        <div v-else class="mx-auto max-w-xs px-8">
                            <p class="text-base font-semibold text-gray-600">Eie</p>
                            <p class="mt-6 flex items-baseline justify-center gap-x-2">
                                <span class="text-5xl font-bold tracking-tight text-gray-900">{{ cost_set["eie"]
                                    }}</span>
                                <span class="text-sm font-semibold leading-6 tracking-wide text-gray-600">NOK</span>
                            </p>
                            <a href="#"
                                class="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Reserver</a>
                            <p class="mt-6 text-xs leading-5 text-gray-600">Invoices and receipts available for
                                easy
                                company reimbursement</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RadioButtonGroup',
    props: {
        size: Number,
        amp: Number,
        extras: Object,
        cost_set: Array
    },
    data() {
        return {
            selectedOption: 50,
            price: 100,
            leie: false,
        };
    },
    methods: {
    },
    computed: {

    }
}

</script>

<style scoped>
.container {
    display: flex;
    height: 100%;
    /* Ensure the container fills the height of its parent */
}

.left-column {
    flex: 0 0 30%;
    /* Set the left column to take up 30% of the container's width */
    background-color: #f0f0f0;
    /* Just for demonstration */
    padding: 20px;
    /* Add padding to the left column */
}

.right-column {
    flex: 1;
    /* Set the right column to take up the remaining space */
    background-color: #e0e0e0;
    /* Just for demonstration */
    padding: 20px;
    /* Add padding to the right column */
}

.extra_enabled {
    visibility: visible;
}

.extra_not_enabled {
    visibility: hidden;
}

input:checked~.toggle_dot {
    transform: translateX(100%);
    background-color: #0082c8;
}

@media (min-width: 640px) {
    .sm\:bg-svg-bottom {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='353' height='304'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23D0D5F6' stroke-width='2'%3E%3Cpath d='M180.29 759c102.087-21.62 155.232-61.312 159.437-119.074 6.307-86.643-231.598-17.186-136.358-198 95.241-180.813 181.318-185.29 136.358-298C294.767 31.216 174.04-27.954 33.79 16.8c-93.501 29.836-144.652 140.545-153.453 332.126'/%3E%3Cpath d='M138.3 759c80.083-18.988 121.774-53.846 125.072-104.575 4.948-76.093-181.679-15.094-106.966-173.89C231.118 321.738 298.64 317.808 263.372 218.82c-35.269-98.986-129.974-150.95-239.995-111.646C-49.97 133.378-90.096 230.605-97 398.859'/%3E%3Cpath d='M102.065 761c60.604-16.56 92.153-46.963 94.65-91.208 3.743-66.367-137.488-13.165-80.949-151.664 56.54-138.5 107.638-141.927 80.948-228.261-26.69-86.335-98.359-131.656-181.618-97.376C-40.41 215.345-70.775 300.145-76 446.892'/%3E%3C/g%3E%3C/svg%3E");
    }
}

.toggle_dot {
    top: -.25rem;
    transition: all 0.3s ease-in-out;
}
</style>