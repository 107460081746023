<template>
    <div class="mx-auto grid max-w-7xl grid-cols-12 gap-4 bg-zinc-50 p-1">
        <div class="col-span-12 rounded-lg border border-gray-500 bg-gray-200 p-2 sm:col-span-8">
            <img :src="warehouse_image" />
        </div>
        <div class="col-span-12 rounded-lg border border-gray-400 bg-gray-200 p-2 sm:col-span-4">
            <div class="m-2 space-y-2">
                <div class="group flex flex-col gap-2 rounded-lg bg-gray-500 p-5" tabindex="1">
                    <div class="flex cursor-pointer items-center justify-between">
                        <span> Areal </span>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/9/96/Chevron-icon-drop-down-menu-WHITE.png"
                            class="h-2 w-3 transition-all duration-500 group-focus:-rotate-180" />
                    </div>
                    <div
                        class="invisible h-auto max-h-0 items-center opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000">
                        <div class="grid grid-cols-1 grid-rows-5 md:grid-cols-1 xl:grid-cols-1 gap-2 p-4">
                            <div class="grid-rows-1">
                                <label>
                                    <input type="radio" value="1" class="peer hidden" name="framework"
                                        @click="set_image(1)">
                                    <div
                                        class="hover:bg-gray-50 flex items-center justify-between px-4 py-2 border-2 rounded-lg cursor-pointer text-sm border-gray-200 group peer-checked:border-blue-500">
                                        <h2 class="font-medium text-gray-700">50 m<sup>2</sup></h2>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor"
                                            class="w-9 h-9 text-blue-600 invisible group-[.peer:checked+&]:visible">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="radio" value="1" class="peer hidden" name="framework"
                                        @click="set_image(2)">
                                    <div
                                        class="hover:bg-gray-50 flex items-center justify-between px-4 py-2 border-2 rounded-lg cursor-pointer text-sm border-gray-200 group peer-checked:border-blue-500">
                                        <h2 class="font-medium text-gray-700">100 m<sup>2</sup></h2>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor"
                                            class="w-9 h-9 text-blue-600 invisible group-[.peer:checked+&]:visible">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="radio" value="1" class="peer hidden" name="framework"
                                        @click="set_image(3)">
                                    <div
                                        class="hover:bg-gray-50 flex items-center justify-between px-4 py-2 border-2 rounded-lg cursor-pointer text-sm border-gray-200 group peer-checked:border-blue-500">
                                        <h2 class="font-medium text-gray-700">200 m<sup>2</sup></h2>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor"
                                            class="w-9 h-9 text-blue-600 invisible group-[.peer:checked+&]:visible">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="radio" value="1" class="peer hidden" name="framework"
                                        @click="set_image(4)">
                                    <div
                                        class="hover:bg-gray-50 flex items-center justify-between px-4 py-2 border-2 rounded-lg cursor-pointer text-sm border-gray-200 group peer-checked:border-blue-500">
                                        <h2 class="font-medium text-gray-700">100 m<sup>2</sup> M/mesanin</h2>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor"
                                            class="w-9 h-9 text-blue-600 invisible group-[.peer:checked+&]:visible">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="radio" value="1" class="peer hidden" name="framework"
                                        @click="set_image(5)">
                                    <div
                                        class="hover:bg-gray-50 flex items-center justify-between px-4 py-2 border-2 rounded-lg cursor-pointer text-sm border-gray-200 group peer-checked:border-blue-500">
                                        <h2 class="font-medium text-gray-700">200 m<sup>2</sup> M/mesanin</h2>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor"
                                            class="w-9 h-9 text-blue-600 invisible group-[.peer:checked+&]:visible">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="group flex flex-col gap-2 rounded-lg bg-gray-500 p-5" tabindex="2">
                    <div class="flex cursor-pointer items-center justify-between">
                        <span> Elektrisk </span>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/9/96/Chevron-icon-drop-down-menu-WHITE.png"
                            class="h-2 w-3 transition-all duration-500 group-focus:-rotate-180" />
                    </div>
                    <div
                        class="invisible h-auto max-h-0 items-center opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000">
                        <div class="grid grid-cols-1 grid-rows-3 md:grid-cols-1 xl:grid-cols-1 gap-2 p-4">
                            <div class="grid-rows-1">
                                <label>
                                    <input type="radio" value="1" class="peer hidden" name="framework"
                                    @click="set_image(6)">
                                    <div
                                        class="hover:bg-gray-50 flex items-center justify-between px-4 py-2 border-2 rounded-lg cursor-pointer text-sm border-gray-200 group peer-checked:border-blue-500">
                                        <h2 class="font-medium text-gray-700">32 AMP, 2x16 kretser med 4 stikk</h2>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor"
                                            class="w-9 h-9 text-blue-600 invisible group-[.peer:checked+&]:visible">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="radio" value="1" class="peer hidden" name="framework"
                                    @click="set_image(7)">
                                    <div
                                        class="hover:bg-gray-50 flex items-center justify-between px-4 py-2 border-2 rounded-lg cursor-pointer text-sm border-gray-200 group peer-checked:border-blue-500">
                                        <h2 class="font-medium text-gray-700">64 AMP, 4x16 kretser med 8 stikk</h2>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor"
                                            class="w-9 h-9 text-blue-600 invisible group-[.peer:checked+&]:visible">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="radio" value="1" class="peer hidden" name="framework"
                                    @click="set_image(8)">
                                    <div
                                        class="hover:bg-gray-50 flex items-center justify-between px-4 py-2 border-2 rounded-lg cursor-pointer text-sm border-gray-200 group peer-checked:border-blue-500">
                                        <h2 class="font-medium text-gray-700">64 AMP, 2x16 kretser med 4 stikk, uttak
                                            el-bil lader.</h2>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor"
                                            class="w-9 h-9 text-blue-600 invisible group-[.peer:checked+&]:visible">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>
                                </label>
                            </div>
                            <!-- <div>
                                <label>
                                    <input type="radio" value="1" class="peer hidden" name="framework">
                                    <div
                                        class="hover:bg-gray-50 flex items-center justify-between px-4 py-2 border-2 rounded-lg cursor-pointer text-sm border-gray-200 group peer-checked:border-blue-500">
                                        <h2 class="font-medium text-gray-700">100 m<sup>2</sup> M/mesanin</h2>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor"
                                            class="w-9 h-9 text-blue-600 invisible group-[.peer:checked+&]:visible">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="radio" value="1" class="peer hidden" name="framework">
                                    <div
                                        class="hover:bg-gray-50 flex items-center justify-between px-4 py-2 border-2 rounded-lg cursor-pointer text-sm border-gray-200 group peer-checked:border-blue-500">
                                        <h2 class="font-medium text-gray-700">200 m<sup>2</sup> M/mesanin</h2>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor"
                                            class="w-9 h-9 text-blue-600 invisible group-[.peer:checked+&]:visible">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>
                                </label>
                            </div> -->
                        </div>
                    </div>
                </div>

                <div class="group flex flex-col gap-2 rounded-lg bg-gray-500 p-5 " tabindex="3">
                    <div class="flex cursor-pointer items-center justify-between">
                        <span> Ekstra </span>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/9/96/Chevron-icon-drop-down-menu-WHITE.png"
                            class="h-2 w-3 transition-all duration-500 group-focus:-rotate-180" />
                    </div>
                    <div
                        class="invisible h-auto max-h-0 items-center opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000">
                        <div class="grid grid-cols-1 grid-rows-5 md:grid-cols-1 xl:grid-cols-1 gap-2 p-4">
                            <div class="grid-rows-1">
                                <label>
                                    <input type="radio" value="1" class="peer hidden" name="framework">
                                    <div
                                        class="hover:bg-gray-50 flex items-center justify-between px-4 py-2 border-2 rounded-lg cursor-pointer text-sm border-gray-200 group peer-checked:border-blue-500">
                                        <h2 class="font-medium text-gray-700">Reoler</h2>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor"
                                            class="w-9 h-9 text-blue-600 invisible group-[.peer:checked+&]:visible">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="radio" value="1" class="peer hidden" name="framework">
                                    <div
                                        class="hover:bg-gray-50 flex items-center justify-between px-4 py-2 border-2 rounded-lg cursor-pointer text-sm border-gray-200 group peer-checked:border-blue-500">
                                        <h2 class="font-medium text-gray-700">Vegghengt lagring</h2>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor"
                                            class="w-9 h-9 text-blue-600 invisible group-[.peer:checked+&]:visible">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="radio" value="1" class="peer hidden" name="framework">
                                    <div
                                        class="hover:bg-gray-50 flex items-center justify-between px-4 py-2 border-2 rounded-lg cursor-pointer text-sm border-gray-200 group peer-checked:border-blue-500">
                                        <h2 class="font-medium text-gray-700">El-bil lader, vegghengt</h2>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor"
                                            class="w-9 h-9 text-blue-600 invisible group-[.peer:checked+&]:visible">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="radio" value="1" class="peer hidden" name="framework">
                                    <div
                                        class="hover:bg-gray-50 flex items-center justify-between px-4 py-2 border-2 rounded-lg cursor-pointer text-sm border-gray-200 group peer-checked:border-blue-500">
                                        <h2 class="font-medium text-gray-700">Slus i gulvet</h2>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor"
                                            class="w-9 h-9 text-blue-600 invisible group-[.peer:checked+&]:visible">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="radio" value="1" class="peer hidden" name="framework">
                                    <div
                                        class="hover:bg-gray-50 flex items-center justify-between px-4 py-2 border-2 rounded-lg cursor-pointer text-sm border-gray-200 group peer-checked:border-blue-500">
                                        <h2 class="font-medium text-gray-700">R&oslash;r opplegg til bad/vask</h2>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor"
                                            class="w-9 h-9 text-blue-600 invisible group-[.peer:checked+&]:visible">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            warehouse_image: '50m2_no_extra.png'
        }
    },
    methods: {
        set_image: function (image) {
            if(this.warehouse_image == "50m2_no_extra.png" && image == 6) {
                image = 6;
            }
            else if(this.warehouse_image == "50m2_no_extra.png" && image == 7) {
                image = 7;
            }
            else if(this.warehouse_image == "50m2_no_extra.png" && image == 8) {
                image = 8;
            }

            switch (image) {
                case 1:
                    this.warehouse_image = '50m2_no_extra.png';
                    break;

                case 2:
                    this.warehouse_image = '100m2_no_extra.png';
                    break;

                case 3:
                    this.warehouse_image = '200m2_no_extra.png';
                    break;

                case 4:
                    this.warehouse_image = '100m2_mezzanine_no_extra.png';
                    break;

                case 5:
                    this.warehouse_image = '200m2_mezzanine_no_extra.png';
                    break;
                
                case 6:
                    this.warehouse_image = '50m2_no_extra_32amp_4stk.png';
                    break;
                case 7:
                    this.warehouse_image = '50m2_no_extra_32amp_8stk.png';
                    break;
                case 8:
                    this.warehouse_image = '50m2_no_extra_64amp_4stk.png';
                    break;
            }
        }
    }

}
</script>
<style scoped></style>